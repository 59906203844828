import axios from 'axios';
import Alpine from 'alpinejs';
import './echo';
import { gtag, install } from 'ga-gtag';

window.Alpine = Alpine;
Alpine.start();

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

install(import.meta.env.VITE_GOOGLE_ANALYTICS_ID);
gtag('set', {
	js: new Date(),
	country: 'IN',
});

setViewHeight();
window.addEventListener('resize', () => {
	setViewHeight();
});

function setViewHeight() {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}
